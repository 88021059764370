<template>
  <div class="aboutus-container">
    <div class="banner">
      <h2> درباره ما </h2>
    </div>
    <div class="text-container">
      <div class="how-works">
        <h3> درباره ما </h3>
        <p>
          کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
          گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
          بر همین اساس سطوح کاربری و کارمزد
        </p>
      </div>
      <div class="text-pic">
        <img src="../../assets/FuckFaceMan.png" alt="">
        <div class="nextpic how-works">
          <h3> تیم ما </h3>
          <p>
            کارمزد معاملات انجام شده در کندل، بر اساس سطح کاربری از سفارش گذار و سفارش پذیر در زمان معامله کسر میگردد. باید در نظر داشت که افزایش سطح کاربری با در نظر
            گرفتن افزایش میزان معاملات ارتقا میابد. هم چنین بررسی میزان معاملات و افزایش سطح کاربری در هر ۲۴ ساعت یک بار به صورت اتومات انجام و سطوح کاربری ارتقا میابد.
            بر همین اساس سطوح کاربری و کارمزد
          </p>
        </div>
      </div>
      <div class="text-pic">
        <div class="item-container">
          <h3> تماس با ما </h3>
          <div class="item">
            <img src="../../assets/Icons/phone.svg" alt="">
            <span> {{info.number}} </span>
          </div>
          <div class="item">
            <img src="../../assets/Icons/Email.svg" alt="">
            <span> {{info.email}} </span>
          </div>
          <div class="item">
            <img src="../../assets/Icons/location.svg" alt="">
            <span> {{info.address}} </span>
          </div>
        </div>
        <l-map style="height: 300px;" class="map" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="markerLatLng"></l-marker>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'AboutUs',
  metaInfo:{
    title:'خانه'
  },
  data() {
    return {
      info:[],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [35.715993,51.406934],
      markerLatLng: [35.715993,51.406934],
      home: true,
      logedin: false,
    }
  },
  methods:{
    async contantUS(){
      const res = await this.$axios.get('/exchange-info',);
      this.info = res.baseDTO || {
        address: '',
        appAndroid: '',
        facebook:'',
        appIOS: '',
        email: 'exchange@mail.com',
        instagram: '',
        linkedin: '',
        number: '22234234 - 021 ',
        telegram: '',
      }
    }
  },
  mounted() {
    this.contantUS();
  },
}
</script>

<style lang="scss" scoped>
.item-container{
  h3{
    font-weight: 700;
    font-size: clamp(24px,3vw,28px);
    color: var(--secondary);
    align-self: flex-start;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 30%;
  row-gap: 32px;
}
.map{
  width: 50% !important;
}
.item{
  display: flex;
  flex-direction: row;
  column-gap: 9px;
  width: 100%;
  img{
    width: 18px !important;
    height: 18px;
  }
}
.text-pic{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  img{
    width: 40%;
  }
  .nextpic{
    width: 50%;
  }
}
.how-works{
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  h3{
    font-weight: 700;
    font-size: clamp(24px,3vw,28px);
    color: var(--secondary);
  }
}
h2{
  font-weight: 700;
  font-size: clamp(24px,3vw,28px);
  color: var(--secondary);
  align-self: flex-start;
}
.text-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 72px;
  padding: 40px 120px;
}
.banner{
  background: url('../../assets/public-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 336px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  //padding: 60px 0;
  h2{
    align-self: flex-end;
    font-weight: 700;
    font-size: clamp(28px,3vw,36px);
    color: var(--black);
  }
}
.aboutus-container{
  display: flex;
  flex-direction: column;
  background: var(--homepage-bg);
  row-gap: 72px;
}
@media only screen and(max-width:1100px){
  .text-container{
    padding: 20px;
  }
  .banner{
    background: unset !important;
    height: 250px !important;
  }
  .text-pic{
    flex-direction: column;
    row-gap: 20px;
    .nextpic{
      width: 100%;
    }
  }
  .item-container{
    width: 100%;
    h3{
      align-self: center;
      color: var(--secondary);
    }
    .item{
      justify-content: center;
    }
  }
  .map{
    width: 100% !important;
  }
}
</style>